<template>
    <div class="feedbackPage" v-if="showModal" :style="{zIndex:$A.getMaxZIndex()}">
        <div class="feedback_mask" :class="isActive?'active':''"></div>

        <div class="feedback_content">
            <div class="reel" :class="isActive?'active':''">
                <div class="reel_header" :class="isActive?'active':''"></div>
                <div class="reel_body" :class="isActive?'active':''">
                    <van-divider
                            :style="{ color: '#02C1AE', borderColor: '#02C1AE', padding: '0 16px' }"
                    >
                        欢迎反馈您遇到的问题
                    </van-divider>

                    <van-checkbox-group v-model="feedbackChecked" @change="feedbackCheckedChange">
                        <van-checkbox :name="item.name" shape="square" v-for="item in feedbackOptions" :key="item.id">
                            {{item.name}}
                        </van-checkbox>
                    </van-checkbox-group>
                    <van-field ref="report_text" class="feedbackInput" v-model="feedbackValue" rows="4" clearable
                               type="textarea" :maxlength="feedbackMaxLen" show-word-limit
                               placeholder="请在此输入其他反馈信息">
                    </van-field>
                    <div class="feedback_btn">
                        <van-button class="btn_item" plain type="info" color="#02C1AE" size="small"
                                    @click="feedbackClose()">暂不反馈
                        </van-button>
                        <van-button class="btn_item" type="info" color="#02C1AE" size="small" @click="feedbackSubmit">
                            提交
                        </van-button>
                    </div>
                </div>
                <div class="reel_footer" :class="isActive?'active':''"></div>
            </div>

        </div>
    </div>
</template>

<script>
    import CommApi from '../api/common'

    export default {
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            questionId:{
                type:Number,
                default: 0
            },
            subjectId:{
                type:Number,
                default:0
            }
        },
        name: "feedback",
        data() {
            return {
                showModal: false,//是否显示组件
                isActive: false,//是否激活组件
                feedbackChecked: [],//反馈预设已选项
                feedbackOptions: [],//反馈快捷选项
                feedbackValue: '',//反馈文本
                feedbackMaxLen: 150,//反馈文本最大长度
            }
        },
        computed: {
            //获取本地存储中的学校id
            schoolId(){
                let schoolId = 0;
                try{
                    schoolId = this.$L.myLocal('__SCHOOL_ID__') || 0;
                }catch (e) {
                    console.log(e)
                }
                return schoolId;
            }

        },
        mounted() {
            this.feedbackOptions = [
                {name: '题目有误', id: 1},
                {name: '答案有误', id: 2},
                {name: '解析有误', id: 3},
                {name: '无法正常显示题目', id: 4},
            ]
        },
        watch: {
            value(val) {
                if (val) {
                    //若为真值，则直接显示组件；
                    this.showModal = val;
                    setTimeout(() => {
                        this.isActive = true;
                    }, 100)

                } else {
                    //若为假值，则先进行动画过度，最后在隐藏组件
                    this.isActive = false;
                    setTimeout(() => {
                        this.showModal = val;
                    }, 100)

                }

            },
            showModal(val) {
                if (val) {
                    console.log(val);
                }
                this.$emit("input", val);
            },
        },
        methods: {
            //预设选项改变监听
            feedbackCheckedChange(e) {
                console.log(e);
            },
            //关闭反馈窗口
            feedbackClose() {
                this.isActive = false;
                setTimeout(() => {
                    this.$emit('input', false);
                }, 300)

            },
            //反馈提交
            feedbackSubmit() {
                if (!this.feedbackChecked.length && !this.feedbackValue) {
                    this.$toast('请选择或填写反馈信息');
                    return false;
                }
                //遍历预选项
                let checkedStr = this.feedbackChecked.join('；');
                let msg = `${checkedStr}；${this.feedbackValue}`;


                console.log(msg);
                let params = {
                    "message": msg,
                    "questionId": this.questionId,
                    "schoolId": this.schoolId,
                    "subjectId": this.subjectId
                }
                //提交反馈信息
                this.$toast.clear();
                this.$toast.loading({
                    message: '反馈提交中，请稍后...',
                    forbidClick: true,
                    duration: 0
                })

                // console.log('反馈信息', params);
                CommApi.feedbackSubmit(params).then(res=>{
                    this.$toast.clear();
                    if(res.success){
                        this.$toast.success('反馈成功\n感谢您的宝贵建议');
                        this.initData();
                        this.feedbackClose();
                    }else{
                        this.$toast.fail(res.msg);
                    }
                })


                //模拟提交成功,加入业务逻辑后可注释
                // setTimeout(() => {
                //     this.$toast.clear();
                //     this.$toast.success('反馈成功\n感谢您的宝贵建议');
                //     this.initData();
                //     this.feedbackClose();
                // }, 1000)

            },
            //初始清空数据
            initData() {
                this.feedbackValue = '';
                this.feedbackChecked = [];
            }
        }
    }
</script>

<style lang="scss">
    .feedbackPage {
        @extend .abs_full_screen;

        .feedback_mask {
            @extend .abs_full_screen;
            background: rgba(0, 0, 0, 1);
            opacity: 0;

            &.active {
                opacity: 0.3;
            }
        }

        .feedback_content {
            @extend .abs_full_screen;
            @extend .flex_row_center;

            .reel {
                width: calc(95% - 24px);
                height: 72px;
                padding: 12px;
                transition: all .3s ease;

                &.active {
                    height: 420px;
                }

                @extend .flex_column_center;

                .reel_header, .reel_footer {
                    height: 36px;
                    width: 100%;
                    border-radius: 10px;
                    /*border: 1px solid #333;*/
                    box-shadow: 4px 4px 14px 4px rgba(0, 0, 0, 0.3);
                    position: relative;
                    transition: all .3s ease;

                    &.active {
                        height: 24px;
                    }

                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 12px;
                        height: 12px;
                        background: #000;
                        border-radius: 5px;
                        z-index: -1;
                    }

                    &:before {
                        left: -4px;
                    }

                    &:after {
                        right: -4px;
                    }
                }

                .reel_header {
                    background: linear-gradient(to bottom, hsl(38, 75%, 50%), hsl(38, 75%, 90%));
                    box-shadow: 4px 4px 14px 4px rgba(0, 0, 0, 0.3);
                }

                .reel_footer {
                    background: linear-gradient(to top, hsl(38, 75%, 50%), hsl(38, 75%, 90%));

                }

                .reel_body {
                    @extend .flex_column;
                    height: 0;
                    /*display: none;*/
                    width: calc(100% - 48px);
                    overflow: hidden;
                    background: #fff;
                    box-shadow: 4px 0px 14px 4px rgba(0, 0, 0, 0.3);
                    transition: all .3s ease;
                    padding: 12px;

                    .feedbackInput {
                        /*flex: 1;*/
                        border: 1px solid #c8c9cc;
                        border-radius: 2px;
                        margin: 12px 0;
                    }

                    .feedback_btn {
                        flex: 1;
                        @extend .flex_row_center;
                        align-items: flex-end;

                        .btn_item {
                            flex: 1;

                            &:first-child {
                                margin-right: 24px;
                            }
                        }
                    }

                    &.active {
                        height: calc(100% - 48px);
                        /*flex: 1;*/
                        /*display: flex;*/
                    }

                    .van-checkbox__label {
                        font-size: 14px;
                    }

                    .van-checkbox__icon .van-icon {
                        width: 14px;
                        height: 14px;
                        font-size: 12px;
                        line-height: 1;
                    }

                    .van-checkbox__icon {
                        font-size: 14px;
                        line-height: 1;
                    }
                }
            }
        }
    }
</style>