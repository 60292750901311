<template>
    <div class="testPage">
        <div class="test_page_content">
            <van-nav-bar
                    title="在线测评"
                    left-arrow
                    :right-text="'反馈'"
                    @click-left="onClickLeft"
                    @click-right="feedbackToggle"
            />
            <div class="test_content">
                <!--                题号、用时-->
                <div class="content_item header">
                    <div class="header_item timer">
                        总计用时 01:14:23
                    </div>
                    <div class="header_item index" ref="indexBox">
                        <div class="index_item" v-for="(item,index) in 22" :key="index">{{index+1}}</div>
                        <div class="index_item img">
                            <img src="../../../public/iconImg/icon_down.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="content_item body"></div>
                <div class="content_item footer">
                    <div class="btn_item submit" :class="{disabled:!submitAble}">下一题</div>
                    <div class="btn_item draft" @click="showDrawPage = true">草稿</div>
                </div>

                <!--                弹窗-->
                <div class="popup_box" v-show="showDrawPage || showFeedback">
                    <van-popup v-model="showDrawPage" class="drawPage" :overlay="false">
                        <div class="box">
                            <div class="user_action">
                                <div class="action_item close" @click="drawClose">
                                    <img src="../../../public/iconImg/icon_close.png" alt="关闭"/>
                                </div>
                                <div class="action_item clear" @click="drawDelete">
                                    <img src="../../../public/iconImg/icon_clear_active.png" alt="清空"/>
                                </div>
                            </div>

                            <in-draw-page ref="in-draw-mask" :inRatio="3" :inLineColor="'#000'" :inLineWidth="3"
                                          :inShadowBlur="3"></in-draw-page>
                        </div>
                    </van-popup>
                    <!--                    反馈 -->
                    <feedback :question-id="currentTopic && Number(currentTopic.id)" :subject-id="currentSubjectId"
                              v-model="showFeedback"></feedback>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Feedback from "../../components/feedback";
    import InDrawPage from "../../components/indraw/Index";

    export default {
        name: "TestPage",
        components: {InDrawPage, Feedback},
        data() {
            return {
                //当前科目
                currentSubjectId: 0,
                //反馈组件是否展现
                showFeedback: false,
                //是否显示草稿
                showDrawPage: false,
                //当前题目信息
                currentTopic: {},
                //是否激活提交按钮
                submitAble: false,
                //是否显示更多题号
                showMore: false
            }
        },
        mounted() {
            // debugger
        },
        activated() {
            this.indexInit();
        },
        methods: {
            //点击返回按钮
            onClickLeft() {
                this.$router.go(-1);
            },
            //反馈组件展现、隐藏
            feedbackToggle() {
                console.log('点击反馈按钮');
                this.showFeedback = true;

                //
                // this.currentSubjectId = this.$L.myLocal('__CURRENT_SUBJECT_ID__');
                // console.log('this.currentTopic__', this.currentSubjectId, this.currentTopic);

            },
            //画板清空
            drawDelete() {
                this.$refs['in-draw-mask'].inDeleteCanvas()
            },
            //画板关闭
            drawClose() {
                this.$refs['in-draw-mask'].inDeleteCanvas()
                this.showDrawPage = false
            },
            //题目索引显示初始化
            indexInit() {
                this.$nextTick(() => {
                    // console.log(this.$refs.indexBox.style);
                    try {
                        let parent = this.$refs.indexBox.getBoundingClientRect();
                        let child = document.querySelector('.index_item').getBoundingClientRect();
                        console.log('parent', parent, 'child', child);
                        let rowMax = Math.floor(parent.width / child.width);
                        console.log('rowMax', rowMax);
                    } catch (e) {

                    }

                })
            }

        },

    }
</script>

<style lang="scss">
    .testPage {
        @extend .abs_full_screen;

        .test_page_content {
            @extend .abs_full_screen;
            @extend .flex_column;
        }

        .van-nav-bar__text {
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
        }

        .test_content {
            @extend .flex_height_auto;
            @extend .flex_column;
            overflow: hidden;

            .content_item {
                &.header {
                    @extend .flex_column;
                    align-items: flex-end;

                    .header_item {
                        &.timer {
                            font-size: 12px;
                            color: rgba(51, 51, 51, 1);
                            padding: 0 12px;
                        }

                        &.index {
                            text-align: left;
                            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

                            .index_item {
                                @extend .flex_row_center;
                                display: inline-flex;
                                height: 25px;
                                width: 25px;
                                border-radius: 50%;
                                color: rgba(51, 51, 51, 1);
                                background: rgba(227, 241, 255, 1);
                                margin: 6px;
                                font-size: 14px;
                                font-weight: bold;

                                &:active, &.active {
                                    background: rgba(1, 159, 232, 1);
                                    color: #fff;
                                }

                                &.img {
                                    background: none;

                                    img {
                                        height: 12px;
                                        width: 12px;
                                    }
                                }

                            }
                        }
                    }
                }

                &.body {
                    @extend .flex_height_auto;
                    /*background: #c3ffe2;*/
                }

                &.footer {
                    height: 45px;
                    @extend .flex_row_center;
                    width: 100%;
                    font-size: 18px;
                    color: #fff;

                    .btn_item {
                        @extend .flex_row_center;
                        @extend .user_select_none;
                        transition: all .3s ease;
                        height: 100%;

                        &.submit {
                            flex: 2;
                            background: linear-gradient(0deg, #019FE8 0%, #6AD0FF 100%);

                            &:active {
                                background: linear-gradient(0deg, #019FE8 100%, #6AD0FF 0%);
                            }

                            &.disabled {
                                color: rgba(102, 102, 102, 1);
                                background: #EEEEEE;
                                pointer-events: none;
                            }
                        }

                        &.draft {
                            flex: 1;
                            background: linear-gradient(0deg, #01C265 0%, #47E297 100%);

                            &:active {
                                background: linear-gradient(0deg, #01C265 100%, #47E297 0%);
                            }
                        }
                    }
                }
            }

            .popup_box {
                @extend .abs_full_screen;

                .feedbackOverlay {

                    .feedback_options {
                        padding: 12px;

                        .option_item {
                            padding: 3px 6px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            font-size: 12px;
                            display: inline-block;
                            margin: 6px;
                            color: #888;

                            &:active {
                                background: #f1f1f1;
                            }
                        }
                    }

                    .van-cell__value.van-field__value {
                        border: 1px solid #888;
                        border-radius: 5px;
                        padding: 6px;
                    }
                }

                .wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    z-index: 24;

                    .van-panel {
                        border-radius: 5px;
                        overflow: auto;
                        width: 90%;
                    }

                    .van-cell {
                        font-size: 14px;
                        line-height: 24px;
                        text-align: center;
                    }

                    .van-field__label {
                        width: 40px;
                        color: #666;
                        padding-right: 12px;
                    }

                    .van-field__word-limit {
                        margin-top: 4px;
                        color: #888;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    .van-field__control {
                        color: #999;
                    }

                    .van-panel__footer {
                        text-align: center;

                        button {
                            background: var(--themecolor);
                            border: none;
                            border-radius: 5px;
                            padding: 6px 12px;
                            height: auto;
                            font-size: 14px;
                            width: 40%;

                            &.cancel_btn {
                                background: none;
                                color: #999;
                                border: 1px solid #999;
                                margin-right: 24px;
                            }
                        }
                    }
                }

                .van-overlay {
                    z-index: 2;
                }
            }

            .drawPage {
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.6);

                .box {
                    @extend .abs_full_screen;

                    .user_action {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 99;

                        .action_item {
                            position: absolute;
                            @extend .flex_row_center;

                            right: 0;
                            width: 36px;
                            height: 36px;
                            border-bottom-left-radius: 5%;
                            border-top-left-radius: 5%;

                            img {
                                width: 24px;
                                height: 24px;
                            }

                            &.close {
                                top: 24px;
                                background: linear-gradient(-21deg, #FF655C 0%, #FF9890 100%);

                                &:active {
                                    background: linear-gradient(-21deg, #FF655C 100%, #FF9890 0%);
                                }
                            }

                            &.clear {
                                top: 72px;
                                background: linear-gradient(-21deg, #FFD201 0%, #F6BF00 100%);

                                &:active {
                                    background: linear-gradient(-21deg, #FFD201 100%, #F6BF00 0%);
                                }
                            }
                        }
                    }


                }
            }
        }


        //判断ipad
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            .van-nav-bar__text {
                font-size: 32px;
            }

            .test_content {

                .content_item {
                    &.header {
                        .header_item {
                            &.timer {
                                font-size: 24px;
                                padding: 0 24px;
                            }

                            &.index {
                                .index_item {
                                    height: 50px;
                                    width: 50px;
                                    /*margin: 6px;*/
                                    font-size: 28px;


                                    &.img {
                                        img {
                                            height: 24px;
                                            width: 24px;
                                        }
                                    }

                                }
                            }
                        }
                    }

                    &.footer {
                        height: 90px;
                        font-size: 36px;

                    }
                }

                .popup_box {
                    @extend .abs_full_screen;

                    .feedbackOverlay {

                        .feedback_options {
                            padding: 24px;

                            .option_item {
                                padding: 6px 12px;
                                border-radius: 10px;
                                font-size: 24px;
                                margin: 12px;

                            }
                        }

                        .van-cell__value.van-field__value {
                            border-radius: 10px;
                            padding: 12px;
                        }
                    }

                    .wrapper {

                        .van-panel {
                            border-radius: 10px;
                        }

                        .van-cell {
                            font-size: 28px;
                            line-height: 48px;
                        }

                        .van-field__label {
                            width: 80px;
                            padding-right: 24px;
                        }

                        .van-field__word-limit {
                            margin-top: 8px;
                            font-size: 24px;
                            line-height: 32px;
                        }


                        .van-panel__footer {

                            button {
                                border-radius: 10px;
                                padding: 12px 24px;
                                font-size: 28px;

                                &.cancel_btn {
                                    margin-right: 48px;
                                }
                            }
                        }
                    }

                    .van-overlay {
                        z-index: 2;
                    }
                }

                .drawPage {

                    .box {

                        .user_action {
                            .action_item {
                                width: 72px;
                                height: 72px;

                                img {
                                    width: 48px;
                                    height: 48px;
                                }

                                &.close {
                                    top: 48px;
                                }

                                &.clear {
                                    top: 144px;
                                }
                            }
                        }


                    }
                }
            }


            .van-nav-bar__arrow, .van-nav-bar__title {
                font-size: 32px;
            }
            .van-nav-bar__left, .van-nav-bar__right {
                font-size: 28px;
            }
            .van-nav-bar__content {
                height: 92px;
            }
            .van-nav-bar {
                line-height: 44px;
            }
        }
    }

</style>
